import { FC } from 'react';

import clsx from 'clsx';

import Typo from '../../Typo';
import { LabelProps } from './Label.types';

const Label: FC<LabelProps> = ({
  testId,
  hasError,
  hasPrefix,
  disabled,
  label,
}) => {
  if (!label) {
    return null;
  }

  return (
    <Typo
      data-testid={`${testId}-label`}
      color={clsx({
        'text-red-300 dark:text-red-200': hasError && !disabled,
        'peer-placeholder-shown:text-gray-400 dark:peer-placeholder-shown:text-gray-200 dark:text-gray-200 text-gray-600 peer-focus:text-gray-600 dark:peer-focus:text-gray-200':
          !hasError && !disabled,
        'text-gray-400 dark:text-gray-300': disabled,
      })}
      weight="300"
      className={clsx(
        'pointer-events-none absolute w-[calc(100%_-_1rem)] origin-top-left translate-x-4 truncate pr-12 leading-5 transition-transform',
        'translate-y-2 scale-90',
        !hasPrefix &&
          'peer-placeholder-shown:translate-y-[18px] peer-focus:translate-y-2 peer-placeholder-shown:scale-100 peer-focus:scale-90',
      )}
    >
      {label}
    </Typo>
  );
};

export default Label;
