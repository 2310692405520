import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgInfoCircleOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="info-circle-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <circle
      cx={12}
      cy={12.0046}
      r={10}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fill="currentColor"
      d="M12.7129 9.6086v6.396h-1.43v-6.396h1.43Zm-1.599-1.95c0-.481.377-.884.884-.884s.871.403.871.884c0 .494-.364.884-.871.884s-.884-.39-.884-.884Z"
    />
  </svg>
);
const Memo = memo(SvgInfoCircleOutlineIcon);
export default Memo;
