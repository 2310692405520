import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgWarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="warning-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.168 22.75h17.664c.65 0 1.213-.141 1.687-.424a2.86 2.86 0 0 0 1.09-1.148c.26-.475.391-1.006.391-1.596 0-.26-.038-.524-.115-.791a3.171 3.171 0 0 0-.31-.77L14.726 2.607a3.008 3.008 0 0 0-1.182-1.194A3.094 3.094 0 0 0 12.006 1c-.536 0-1.052.138-1.55.413-.49.268-.884.666-1.182 1.194L.424 18.033A3.05 3.05 0 0 0 0 19.583c0 .589.126 1.12.379 1.595.26.482.627.865 1.102 1.148.474.283 1.036.424 1.687.424Zm.011-1.802c-.398 0-.719-.141-.964-.424a1.402 1.402 0 0 1-.356-.942 1.698 1.698 0 0 1 .15-.688l8.838-15.415c.13-.222.298-.382.505-.482.206-.107.424-.16.654-.16.222 0 .432.053.631.16.207.1.375.26.505.482l8.826 15.426a1.355 1.355 0 0 1-.195 1.618c-.244.284-.566.425-.964.425H3.18Zm8.827-5.945c.574 0 .864-.302.872-.907l.172-6.06a.908.908 0 0 0-.298-.723 1.026 1.026 0 0 0-.758-.299c-.313 0-.57.096-.769.287a.943.943 0 0 0-.275.723l.149 6.072c.015.605.317.907.907.907Zm0 3.73c.329 0 .616-.115.86-.344.253-.23.38-.513.38-.85 0-.336-.123-.62-.368-.849a1.232 1.232 0 0 0-.872-.344 1.21 1.21 0 0 0-.873.356c-.244.23-.367.508-.367.837 0 .33.123.613.367.85.245.23.536.344.873.344Z"
    />
  </svg>
);
const Memo = memo(SvgWarningIcon);
export default Memo;
