import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgCircleCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="circle-check-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <circle cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={1.5} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m8 12.6 1.9237 2.0198a.5.5 0 0 0 .7241 0L16 9"
    />
  </svg>
);
const Memo = memo(SvgCircleCheckIcon);
export default Memo;
