import { FC } from 'react';

import clsx from 'clsx';

import Typo from '../../Typo';
import { PrefixProps } from './Prefix.types';

const Prefix: FC<PrefixProps> = ({ prefix, testId, hasLabel }) => {
  if (!prefix) {
    return null;
  }

  return (
    <Typo
      data-testid={`${testId}-prefix`}
      className={clsx(
        'pointer-events-none pl-4 leading-5',
        hasLabel ? 'self-end pb-[7px]' : 'self-center',
      )}
      color="text-gray-400 dark:text-gray-300"
      weight="300"
    >
      {prefix}
    </Typo>
  );
};

export default Prefix;
