import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgMapOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="map-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.3799 21.13c-.2 0-.39-.08-.53-.22l-6.38-6.38c-.29-.29-.29-.77 0-1.06L21.2 5.74c.19-.19.4799-.26.7399-.19.26.08.46.29.52.55.19.85.29 1.8.29 2.9v6c0 2.77-.58 4.64-1.84 5.91-.14.14-.35.17-.53.22Zm-5.32-7.13 5.26 5.26c.63-.97.93-2.35.93-4.26V9c0-.41-.01-.79-.04-1.15l-6.15 6.15Z"
    />
    <path
      fill="currentColor"
      d="M6.27 22.48c-.06 0-.11-.01-.17-.02-3.31-.76-4.85-3.13-4.85-7.46V9c0-5.43 2.32-7.75 7.75-7.75h6c4.33 0 6.7 1.54 7.46 4.85.06.25-.02.52-.2.7L6.8 22.26c-.14.14-.33.22-.53.22ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9v6c0 3.47.96 5.21 3.29 5.9L20.89 6.05c-.68-2.33-2.43-3.29-5.9-3.29H9v-.01Z"
    />
    <path
      fill="currentColor"
      d="M14.9999 22.7499h-6c-1.1 0-2.04-.09-2.9-.29-.27-.06-.48-.26-.55-.52-.08-.26 0-.54.19-.74l7.73-7.73c.29-.29.77-.29 1.06 0l6.38 6.38c.14.14.22.33.22.53s-.08.39-.22.53c-1.27 1.26-3.14 1.84-5.91 1.84Zm-7.15-1.54c.36.03.74.04 1.15.04h6c1.92 0 3.29-.3 4.26-.93l-5.26-5.26-6.15 6.15ZM9.12 13.31c-.63 0-1.26-.23-1.76-.7-1.59-1.51-2.23-3.17-1.85-4.79.38-1.66 1.83-2.78 3.61-2.78 1.7799 0 3.23 1.12 3.61 2.78.37 1.63-.2701 3.28-1.8601 4.79-.49.46-1.12.7-1.75.7ZM6.97 8.15c-.32 1.36.6 2.5801 1.43 3.3701.41.3899 1.04.3899 1.44 0 .8199-.78 1.7399-2 1.4299-3.37-.27-1.19-1.33-1.62-2.15-1.62-.82 0-1.87.43-2.15 1.62Z"
    />
    <path
      fill="currentColor"
      d="M9.1499 9.4902c-.55 0-1-.45-1-1s.44-1 1-1h.01c.55 0 1 .45 1 1s-.46 1-1.01 1Z"
    />
  </svg>
);
const Memo = memo(SvgMapOutlineIcon);
export default Memo;
