const amountWithCurrency = (
  amount: number | null = 0,
  currency: string | null = 'EUR',
  maximumFractionDigits?: number,
) => {
  if (currency === 'NGN') {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits,
    }).format((amount || 0) / 100);
  }

  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency || 'EUR',
    maximumFractionDigits,
  }).format((amount || 0) / 100);
};

export default amountWithCurrency;
