import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgProfileOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="profile-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.16 10.87c-.1-.01-.22-.01-.33 0-2.38-.08-4.27-2.03-4.27-4.43C7.56 3.99 9.54 2 12 2c2.45 0 4.44 1.99 4.44 4.44-.01 2.4-1.9 4.35-4.28 4.43Zm-5 3.69c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0Z"
    />
  </svg>
);
const Memo = memo(SvgProfileOutlineIcon);
export default Memo;
