import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgCircleXIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="circle-x-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <circle cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={1.5} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m9 9 6 6m-6 0 6-6"
    />
  </svg>
);
const Memo = memo(SvgCircleXIcon);
export default Memo;
