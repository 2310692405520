import {
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import amountWithCurrency from '@bluecodecom/formatters/amountWithCurrency';

import InputBase from '../Input';
import { FormattedCurrencyInputProps } from './FormattedCurrencyInput.types';

const FormattedCurrencyInput: FC<FormattedCurrencyInputProps> = ({
  value,
  hasError,
  helperText,
  label,
  isIconClickable,
  icon,
  maxValue,
  currency,
  disabled,
  setValue,
  onFocus,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const displayValue = useMemo(
    () => amountWithCurrency(value, currency, 2),
    [currency, value],
  );

  const handleFocus = useCallback(() => {
    onFocus?.();
    setIsFocused(true);
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    onBlur?.();
  }, [onBlur]);

  const handleContainerClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue((p) => {
        const n = parseInt(e.target.value || '0');
        const correctValue = isNaN(n) ? p : n;
        return maxValue ? Math.min(correctValue, maxValue) : correctValue;
      });
    },
    [maxValue, setValue],
  );

  return (
    <div className="relative" onClick={handleContainerClick}>
      <InputBase
        testId="ui-input"
        readOnly
        wrapperHeightClassName=""
        fontSizeClassName="text-2xl"
        inputMode="numeric"
        label={label}
        className="caret-transparent"
        isFocused={isFocused}
        value={displayValue}
        hasError={hasError}
        helperText={helperText}
        disabled={disabled}
        isIconClickable={isIconClickable}
        icon={icon}
      />
      <input
        data-testid="ui-hidden-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        ref={inputRef}
        disabled={disabled}
        inputMode="numeric"
        className="absolute !text-transparent selection:bg-transparent bg-transparent outline-none caret-transparent top-0"
      />
    </div>
  );
};

export default FormattedCurrencyInput;
