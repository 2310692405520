import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgEmailOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="email-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17 21.25H7c-3.65 0-5.75-2.1-5.75-5.75v-7c0-3.65 2.1-5.75 5.75-5.75h10c3.65 0 5.75 2.1 5.75 5.75v7c0 3.65-2.1 5.75-5.75 5.75Zm-10-17c-2.86 0-4.25 1.39-4.25 4.25v7c0 2.86 1.39 4.25 4.25 4.25h10c2.86 0 4.25-1.39 4.25-4.25v-7c0-2.86-1.39-4.25-4.25-4.25H7Z"
    />
    <path
      fill="currentColor"
      d="M11.9998 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5c-.32-.26-.38-.73-.12-1.05.26-.32.73-.38 1.05-.12l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79Z"
    />
  </svg>
);
const Memo = memo(SvgEmailOutlineIcon);
export default Memo;
