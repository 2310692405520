import { useEffect, useRef } from 'react';
import { isAndroid } from 'react-device-detect';

const useScrollToInput = (enabled = true) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isAndroid) {
      return;
    }

    if (!enabled) {
      return;
    }

    const onFocus = () => {
      setTimeout(() => {
        inputRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
    };

    inputRef.current?.addEventListener('focus', onFocus);
    inputRef.current?.addEventListener('input', onFocus);

    return () => {
      inputRef.current?.removeEventListener('focus', onFocus);
      inputRef.current?.removeEventListener('input', onFocus);
    };
  }, [enabled]);

  return inputRef;
};

export default useScrollToInput;
