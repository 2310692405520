import { FC } from 'react';

import clsx from 'clsx';

import Typo from '../../Typo';
import { HelperTextProps } from './HelperText.types';

const HelperText: FC<HelperTextProps> = ({
  testId,
  hasError,
  disabled,
  helperText,
  className,
}) => {
  if (!helperText) {
    return null;
  }

  return (
    <Typo
      size="sm"
      weight="300"
      color={clsx(
        hasError && !disabled
          ? 'text-red-300 dark:text-red-200'
          : 'text-gray-500 dark:text-gray-0',
      )}
      data-testid={`${testId}-helper-text`}
      className={clsx('leading-4', className)}
    >
      {helperText}
    </Typo>
  );
};

export default HelperText;
