import { forwardRef } from 'react';

import clsx from 'clsx';

import { WrapperProps } from './Wrapper.types';

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(
  (
    {
      children,
      hasError,
      disabled,
      isFocused,
      heightClassName = 'h-14',
      innerProps,
    },
    ref,
  ) => (
    <div
      ref={ref}
      {...innerProps}
      className={clsx(
        'relative flex overflow-hidden rounded-2xl border transition-colors box-border',
        {
          'border-red-300 dark:border-red-200': hasError && !disabled,
          'border-gray-500 dark:border-gray-0':
            !hasError && isFocused && !disabled,
          'border-gray-200 dark:border-gray-400':
            !hasError && !isFocused && !disabled,
          'border-gray-200 bg-gray-100 dark:border-gray-400 dark:bg-gray-600':
            disabled,
          'bg-gray-0 dark:bg-gray-500': !disabled,
          'shadow-input dark:shadow-darkInput': !disabled && !isFocused,
          'shadow-inputFocused dark:shadow-darkInput': !disabled && isFocused,
        },
        heightClassName,
      )}
    >
      {children}
    </div>
  ),
);

export default Wrapper;
