import { MouseEventHandler, useCallback, useId } from 'react';

import clsx from 'clsx';

import { ToggleButtonProps } from './ToggleButton.types';

const labelClasses = {
  default:
    'relative inline-block h-8 w-14 select-none rounded-2xl border border-gray-300 bg-gray-300',
  before:
    'before:absolute before:top-[-0.0625rem] before:left-[-0.0625rem] before:block before:h-8 before:w-14 before:rounded-full before:transition-all before:duration-300 before:ease-in-out',
  after:
    'after:absolute after:left-[0.0625rem] after:top-[0.0625rem] after:block after:h-[1.75rem] after:w-[1.75rem] after:rounded-full after:bg-gray-0 after:shadow-[0_0_0_1px_hsla(0,0%,0%,0.1),0_4px_0px_0_hsla(0,0%,0%,0.04),0_4px_9px_hsla(0,0%,0%,0.13),0_3px_3px_hsla(0,0%,0%,0.05)] after:transition-all after:duration-300 after:ease-in-out',
  checked:
    'dark:before:peer-checked:bg-blue-300 before:peer-checked:bg-blue-400 after:peer-checked:left-[1.5625rem]',
};

const ToggleButton = ({
  id: outerId,
  checked,
  className,
  isLoading,
  onChange,
  isDisabled,
}: ToggleButtonProps) => {
  const id = useId();

  const toggleButtonId = outerId || id;

  const handleStopPropagation: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <div className={clsx('relative flex', isLoading && 'pointer-events-none')}>
      <input
        type="checkbox"
        className="absolute top-0 bottom-0 left-0 right-0 opacity-0 peer"
        id={toggleButtonId}
        checked={checked}
        onClick={handleStopPropagation}
        onChange={onChange}
      />
      <label
        htmlFor={toggleButtonId}
        className={clsx(
          labelClasses.default,
          labelClasses.after,
          labelClasses.before,
          labelClasses.checked,
          isLoading && 'bc-toggle-button-loading',
          isDisabled && 'opacity-50 pointer-events-none',
          className,
        )}
      />
    </div>
  );
};

export default ToggleButton;
