import Typo from '../Typo';
import XssBase from '../Xss';
import { ExtendedXssProps } from './ExtendedXss.types';

const ExtendedXss = ({
  text,
  isLoading,
  className,
  color,
  weight = '300',
}: ExtendedXssProps) => (
  <Typo
    className={className}
    weight={weight}
    color={color}
    isLoading={isLoading}
  >
    <XssBase
      text={text}
      extendWhiteList={{
        h1: ['class', 'style'],
        h2: ['class', 'style'],
        h3: ['class', 'style'],
        h4: ['class', 'style'],
        h5: ['class', 'style'],
        h6: ['class', 'style'],
        div: ['class', 'style'],
        ol: ['class', 'style'],
        blockquote: ['class', 'style'],
        li: ['class', 'style'],
        ul: ['class', 'style'],
        a: ['class', 'href', 'target'],
        code: ['class', 'style'],
        svg: ['width', 'height', 'viewBox', 'style', 'class', 'fill'],
        path: [
          'fill-rule',
          'clip-rule',
          'fill',
          'd',
          'stroke',
          'stroke-width',
          'stroke-linecap',
          'stroke-linejoin',
        ],
        img: ['class', 'style', 'src', 'alt', 'width', 'height'],
        g: ['clip-path'],
        span: ['class', 'style'],
        p: ['class', 'style'],
        clipPath: ['id'],
        rect: ['width', 'height', 'fill'],
        defs: [],
        iframe: ['src', 'width', 'height', 'allowfullscreen', 'frameborder'],
      }}
    />
  </Typo>
);

export default ExtendedXss;
