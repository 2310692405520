import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgTermsOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="terms-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.005 1.1062a4.75 4.75 0 0 0-4.01 0l-5 2.328A4.7507 4.7507 0 0 0 2.25 7.741v2.6877c0 6.5309 5.1049 10.6109 7.7314 12.2864 1.2414.792 2.7958.792 4.0372 0 2.6265-1.6755 7.7314-5.7555 7.7314-12.2864v-2.688a4.7502 4.7502 0 0 0-2.745-4.3063l-5-2.328Zm-3.3768 1.3599a3.25 3.25 0 0 1 2.7436 0l5 2.328A3.2502 3.2502 0 0 1 20.25 7.7405v2.6881c0 5.7032-4.4842 9.3927-7.0381 11.0219-.7494.478-1.6744.478-2.4238 0C8.2341 19.8213 3.75 16.1318 3.75 10.4286V7.7409a3.2507 3.2507 0 0 1 1.8782-2.9468l5-2.328Zm4.8621 7.2443a.75.75 0 0 0-1.0606-1.0607L10.66 12.4194l-1.0797-1.0797a.75.75 0 1 0-1.0606 1.0607l1.61 1.61a.75.75 0 0 0 1.0606 0l4.3-4.3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgTermsOutlineIcon);
export default Memo;
