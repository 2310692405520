import {
  FocusEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';

import HelperText from './HelperText';
import Icon from './Icon';
import { InputProps } from './Input.types';
import Label from './Label';
import Prefix from './Prefix';
import Wrapper from './Wrapper';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      prefix,
      hasError,
      label,
      icon,
      helperText,
      className,
      testId = 'input',
      placeholder,
      disabled,
      isIconClickable,
      disableFocusStyle,
      isFocused: incomingIsFocused = false,
      fontSizeClassName = 'text-base leading-5',
      wrapperHeightClassName,
      helperTextClassName,
      inputClassName,
      onBlur,
      onFocus,
      ...inputProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const placeholderText = useMemo(() => {
      if (!placeholder) {
        return '';
      }

      if (!label || !!prefix || isFocused) {
        return placeholder;
      }

      return '';
    }, [isFocused, label, placeholder, prefix]);

    const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setIsFocused(true);
        onFocus?.(e);
      },
      [onFocus],
    );

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setIsFocused(false);
        onBlur?.(e);
      },
      [onBlur],
    );

    useEffect(() => {
      setIsFocused(incomingIsFocused);
    }, [incomingIsFocused]);

    return (
      <div
        className={clsx(
          'flex flex-col space-y-2 text-left',
          { 'cursor-not-allowed': disabled },
          className,
        )}
        data-testid={`${testId}-container`}
      >
        <Wrapper
          isFocused={disableFocusStyle ? false : isFocused}
          heightClassName={wrapperHeightClassName}
          disabled={disabled}
          hasError={hasError}
        >
          <Prefix testId={testId} hasLabel={Boolean(label)} prefix={prefix} />
          <input
            ref={ref}
            style={{
              paddingLeft: prefix ? 4 : 16,
              paddingRight: hasError || Boolean(icon) ? 48 : 16,
              paddingTop: label ? 28 : 0,
              paddingBottom: label ? 8 : 1,
            }}
            disabled={disabled}
            data-testid={`${testId}-input`}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholderText}
            className={clsx(
              'h-full w-full flex-1 peer bg-transparent font-primary font-medium outline-none disabled:cursor-not-allowed placeholder:text-gray-300 placeholder:font-light',
              {
                'text-gray-600 dark:text-gray-0': !disabled,
                'text-gray-400 dark:text-gray-200': disabled,
              },
              inputClassName,
              fontSizeClassName,
            )}
            {...inputProps}
          />
          <Label
            testId={testId}
            disabled={disabled}
            hasError={hasError}
            hasPrefix={!!prefix}
            label={label}
          />
          <Icon
            icon={icon}
            testId={testId}
            hasError={hasError}
            disabled={disabled}
            isIconClickable={isIconClickable}
          />
        </Wrapper>
        <HelperText
          hasError={hasError}
          disabled={disabled}
          testId={testId}
          className={helperTextClassName}
          helperText={helperText}
        />
      </div>
    );
  },
);

export default Input;
