import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgGasPumpOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="gas-pump-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.7226 1h.0548c.9087 0 1.6288 0 2.2123.0398.596.0407 1.1039.1254 1.578.3218a4.75 4.75 0 0 1 2.5707 2.5706c.1964.4742.2811.9821.3218 1.5781.0398.5834.0398 1.3036.0398 2.2123v8.5548c0 .3514 0 .6746-.0023.9726H18.75c.6904 0 1.25-.5596 1.25-1.25V8.7241a1.25 1.25 0 0 0-.2897-.8002l-.7865-.9437a.75.75 0 0 1 1.1524-.9603l.7864.9437A2.7497 2.7497 0 0 1 21.5 8.7241V16c0 1.5188-1.2312 2.75-2.75 2.75h-1.3111c-.047.4861-.1333.9141-.3005 1.3177a4.75 4.75 0 0 1-2.5707 2.5707c-.4741.1964-.982.2811-1.578.3218C12.4063 23 11.6861 23 10.7775 23h-.055c-.9086 0-1.6288 0-2.2122-.0398-.596-.0407-1.1039-.1254-1.578-.3218a4.75 4.75 0 0 1-2.5707-2.5707c-.1964-.4741-.2811-.982-.3218-1.578C4 17.9062 4 17.1861 4 16.2774V7.7226c0-.9087 0-1.6288.0398-2.2123.0407-.596.1254-1.1039.3218-1.578a4.75 4.75 0 0 1 2.5706-2.5707c.4742-.1964.9821-.2811 1.5781-.3218C9.0937 1 9.814 1 10.7226 1ZM8.6124 2.5363c-.5196.0355-.8452.103-1.1061.211a3.25 3.25 0 0 0-1.759 1.759c-.108.2609-.1755.5865-.211 1.1061C5.5004 6.1388 5.5 6.808 5.5 7.75v8.5c0 .9421.0004 1.6112.0363 2.1376.0355.5196.103.8452.211 1.1061a3.2497 3.2497 0 0 0 1.759 1.7589c.2609.1081.5865.1756 1.1061.2111.5264.0359 1.1955.0363 2.1376.0363.9421 0 1.6112-.0004 2.1376-.0363.5196-.0355.8452-.103 1.1061-.2111a3.2493 3.2493 0 0 0 1.7589-1.7589c.1081-.2609.1756-.5865.2111-1.1061.0359-.5264.0363-1.1955.0363-2.1376v-8.5c0-.9421-.0004-1.6112-.0363-2.1376-.0355-.5196-.103-.8452-.2111-1.1061a3.2497 3.2497 0 0 0-1.7589-1.759c-.2609-.108-.5865-.1755-1.1061-.211C12.3612 2.5004 11.6921 2.5 10.75 2.5c-.9421 0-1.6112.0004-2.1376.0363ZM7.7 9c-.3866 0-.7.3358-.7.75s.3134.75.7.75h6.1c.3866 0 .7-.3358.7-.75S14.1866 9 13.8 9H7.7Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgGasPumpOutlineIcon);
export default Memo;
