import { Suspense, lazy, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import { nativeService } from '@bluecodecom/native';

import { BlurredBackground } from '@bluecodecom/ui';

import { sessionService } from '@bluecodecom/pay-at-pump-webview/features/session';
import { trackingService } from '@bluecodecom/pay-at-pump-webview/features/tracking';

import './i18n';

const App = lazy(() => import('./app'));

const Main = () => {
  useEffect(() => {
    nativeService.hideNavigation();
    nativeService.allowResumeMiniApp();

    trackingService.provider = sessionService.provider;
    trackingService.trackEvent('Opened');
  }, []);

  return (
    <LoaderProvider>
      <ThemeProvider observe>
        <BlurredBackground type="twilight" />
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
        </BrowserRouter>
        <div className="h-4 left-0 w-full bottom-0 fixed z-10 bg-[linear-gradient(180deg,_transparent_0%,#F4F2F6_100%)] dark:bg-[linear-gradient(0deg,_#252527_0%,_transparent_100%)]" />
      </ThemeProvider>
    </LoaderProvider>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
