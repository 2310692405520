import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgTransactionsOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="transactions-outline-icon"
    viewBox="0 0 24 25"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.75 8.5076c0-2.9666 2.0591-5.4457 4.8282-6.088l-.2713.4521a.75.75 0 1 0 1.2862.7717l1.05-1.75A.75.75 0 0 0 8 .7576c-4.2842 0-7.75 3.4657-7.75 7.75a.75.75 0 0 0 1.5 0Zm13.7495 6.1174c-.508-2.8394-2.7474-5.079-5.5869-5.587.0894-2.9171 2.4784-5.2504 5.4174-5.2504 2.9958 0 5.42 2.4242 5.42 5.42 0 2.9391-2.3333 5.328-5.2505 5.4174Zm.0959 1.4976c-.134 3.7059-3.1766 6.6649-6.9154 6.6649-3.8242 0-6.92-3.0957-6.92-6.9199 0-3.7354 2.9536-6.7758 6.6546-6.9151.134-3.7059 3.1766-6.6649 6.9154-6.6649 3.8242 0 6.92 3.0958 6.92 6.92 0 3.7353-2.9535 6.7758-6.6546 6.915ZM3.26 15.8676c0-2.9958 2.4242-5.4201 5.42-5.4201.1201 0 .233.0067.3863.0158l.043.0025c2.6491.2041 4.7672 2.3161 4.9808 4.9625.0006.0743.0029.1465.0049.2099v.0001l.0004.0109c.0025.0782.0046.147.0046.2184 0 2.9957-2.4242 5.4199-5.42 5.4199s-5.42-2.4242-5.42-5.4199Zm19.74-.11a.75.75 0 0 1 .75.75c0 4.2842-3.4658 7.75-7.75 7.75a.75.75 0 0 1-.6431-1.1359l1.05-1.75a.75.75 0 1 1 1.2862.7717l-.2713.4522c2.7691-.6424 4.8282-3.1215 4.8282-6.088a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgTransactionsOutlineIcon);
export default Memo;
