import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgPrivacyPolicyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="privacy-policy-icon"
    viewBox="0 0 25 25"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.5019 4.2778H7.416c-.5523 0-1 .4478-1 1v14.7477c0 .5523.4477 1 1 1h3.2517v1H7.416c-1.1046 0-2-.8954-2-2V5.2778c0-1.1045.8954-2 2-2h11.086c1.1046 0 2 .8955 2 2v5.6335h-1V5.2778c0-.5522-.4477-1-1-1ZM8.4139 6.9351c-.4055 0-.7342.3312-.7342.7368 0 .4055.3287.7368.7343.7368.4055 0 .7343-.3313.7343-.7368 0-.4056-.3288-.7368-.7343-.7368Zm2.76.2304a.5062.5062 0 0 0 0 1.0124h6.0902a.5062.5062 0 0 0 0-1.0124h-6.0902Zm0 2.7334a.5063.5063 0 0 0 0 1.0124h4.5551a.5062.5062 0 0 0 0-1.0124h-4.5551Zm-3.4942.5063c0-.4055.3287-.7367.7342-.7367.4056 0 .7343.3312.7343.7367 0 .4056-.3287.7368-.7343.7368-.4055 0-.7342-.3312-.7342-.7368Zm6.5264 5.4981h-.8892v6.1341h8.5536v-6.1341h-.8892v.0057a.0063.0063 0 0 1-.0063.0063h-6.7626a.0063.0063 0 0 1-.0063-.0063v-.0057Zm0-1h-.8892c-.5523 0-1 .4477-1 1v6.1341c0 .5523.4477 1 1 1h8.5536c.5523 0 1-.4477 1-1v-6.1341c0-.5523-.4477-1-1-1h-.8892v-.1153c0-1.8709-1.5167-3.3876-3.3876-3.3876s-3.3876 1.5167-3.3876 3.3876v.1153Zm1 0v-.1153c0-1.3186 1.0689-2.3876 2.3876-2.3876 1.3186 0 2.3876 1.069 2.3876 2.3876v.1153h-4.7752Zm3.4799 4.067c0 .6033-.4891 1.0924-1.0924 1.0924-.6033 0-1.0924-.4891-1.0924-1.0924 0-.6033.4891-1.0924 1.0924-1.0924.6033 0 1.0924.4891 1.0924 1.0924Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPrivacyPolicyIcon);
export default Memo;
