import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgDangerOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="danger-outline-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 14.75c-.41 0-.75-.34-.75-.75V9c0-.41.34-.75.75-.75s.75.34.75.75v5c0 .41-.34.75-.75.75Zm0 3.2501c-.06 0-.13-.01-.2-.02-.06-.01-.12-.03-.18-.06-.06-.02-.12-.05-.18-.09l-.15-.12c-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71l.15-.12c.06-.04.12-.07.18-.09.06-.03.12-.05.18-.06.13-.03.27-.03.39 0 .07.01.13.03.19.06.06.02.12.05.18.09l.15.12c.18.19.29.45.29.71 0 .26-.11.52-.29.71l-.15.12c-.06.04-.12.07-.18.09-.06.03-.12.05-.19.06-.06.01-.13.02-.19.02Z"
    />
    <path
      fill="currentColor"
      d="M18.06 22.1601H5.94c-1.95 0-3.44-.71-4.2-1.99-.75-1.28-.65-2.93.3-4.64l6.06-10.9c1-1.8 2.38-2.79 3.9-2.79s2.9.99 3.9 2.79l6.06 10.91c.95 1.71 1.06 3.35.3 4.64-.76 1.27-2.25 1.98-4.2 1.98ZM12 3.3401c-.94 0-1.86.72-2.59 2.02L3.36 16.27c-.68 1.22-.79 2.34-.32 3.15.47.81 1.51 1.25 2.91 1.25h12.12c1.4 0 2.43-.44 2.91-1.25s.36-1.92-.32-3.15L14.59 5.36c-.73-1.3-1.65-2.02-2.59-2.02Z"
    />
  </svg>
);
const Memo = memo(SvgDangerOutlineIcon);
export default Memo;
