import {
  ToastContainer as ReactToastifyToastContainer,
  ToastContainerProps,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  CircleCheckIcon,
  CircleXIcon,
  DangerOutlineIcon,
  InfoCircleOutlineIcon,
} from '@bluecodecom/icons';

const ToastContainer = (props: ToastContainerProps) => (
  <ReactToastifyToastContainer
    stacked
    className="bc-toast-container"
    position="top-center"
    theme="colored"
    hideProgressBar
    closeOnClick
    closeButton={false}
    autoClose={3000}
    toastClassName="bc-toast"
    icon={({ type }) => {
      switch (type) {
        case 'success':
          return <CircleCheckIcon />;
        case 'error':
          return <CircleXIcon />;
        case 'warning':
          return <DangerOutlineIcon />;
        case 'info':
        default:
          return <InfoCircleOutlineIcon />;
      }
    }}
    {...props}
  />
);

export default ToastContainer;
