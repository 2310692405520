import { FC, useMemo } from 'react';

import clsx from 'clsx';

import { WarningIcon } from '@bluecodecom/icons';

import { IconProps } from './Icon.types';

const Icon: FC<IconProps> = ({
  hasError,
  disabled,
  icon,
  isIconClickable,
  testId,
}) => {
  const iconComponent = useMemo(() => {
    if (hasError && !icon) {
      return (
        <WarningIcon
          data-testid={`${testId}-icon-error`}
          className="text-red-300 dark:text-red-200"
        />
      );
    }

    return icon;
  }, [hasError, icon, testId]);

  if (!iconComponent) {
    return null;
  }

  return (
    <div
      data-testid={`${testId}-icon`}
      className={clsx(
        'absolute top-0 bottom-0 right-0 flex w-12 items-center justify-end pr-4',
        isIconClickable ? 'cursor-pointer' : 'pointer-events-none',
      )}
    >
      {iconComponent}
    </div>
  );
};

export default Icon;
