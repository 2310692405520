import { FC, useMemo } from 'react';

import clsx from 'clsx';

import { useThemeContext } from '@bluecodecom/theme';

import { BlurredBackgroundProps } from './BlurredBackground.types';
import sunriseDarkSrc from './assets/sunrise-dark.jpg';
import sunriseLightSrc from './assets/sunrise-light.jpg';
import twilightDarkSrc from './assets/twilight-dark.jpg';
import twilightLightSrc from './assets/twilight-light.jpg';

const BlurredBackground: FC<BlurredBackgroundProps> = ({ type, className }) => {
  const { isDarkMode } = useThemeContext();

  const bgUrl = useMemo(() => {
    switch (type) {
      case 'sunrise':
        return isDarkMode ? sunriseDarkSrc : sunriseLightSrc;
      case 'twilight':
        return isDarkMode ? twilightDarkSrc : twilightLightSrc;
      default:
        console.error('BlurredBackground has no background for type:', type);
        return '';
    }
  }, [isDarkMode, type]);

  if (!bgUrl) return null;

  return (
    <div
      className={clsx(
        'absolute top-0 left-0 right-0 bottom-0 bg-center bg-cover',
        className,
      )}
      style={{ backgroundImage: `url(${bgUrl})` }}
    />
  );
};

export default BlurredBackground;
